import * as React from "react";
import { Link, graphql } from "gatsby";
import { gql } from "@apollo/client";
import { format, differenceInDays } from "date-fns";
import Truncate from "react-truncate";
import striptags from "striptags";

import {
  InstitutionImage,
  PersonImage,
  ExperimentImage,
  HistologyImage,
  PresentationImage,
  PublicationImage,
  FoundationImage,
} from "./Images";
import {
  Label,
  SnippetLabel,
  ExperimentStatusLabel,
} from "../components/Labels";
import { StatBlock, StatGroup } from "./Stats";
import { Smallcaps } from "./Prose";
import { Image } from "./Image";
import { RainbowLabel } from "./next/Algolia";
import { Link as NLink } from "./next/Link";

export const Card = ({ to, children }) => (
  <Link
    to={to}
    className="relative bg-white flex flex-col h-full rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-500 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    {children}
  </Link>
);

export const BorderlessCard = ({ children }) => (
  <div className="relative bg-white flex flex-col rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-500 ease-in-out">
    {children}
  </div>
);

export const CardEntity = ({ entity, showType }) =>
  showType ? (
    <div className="md text-navy-900 p-2">
      <div className="flex">
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <Smallcaps>{entity}</Smallcaps>
        </div>
      </div>
    </div>
  ) : null;

export const CardBody = ({ children }) => (
  <div className="flex-1 bg-white pb-6 px-6 flex flex-col justify-between">
    {children}
  </div>
);

export const CardImage = ({ children }) => (
  <div className="flex-shrink-0">
    <div className="h-48 w-full object-cover">{children}</div>
  </div>
);

export const CardTitle = ({ children }) => (
  <h3 className="mt-3 text-xl leading-7 text-gray-900">
    <Truncate lines={2}>{children}</Truncate>
  </h3>
);

export const CardSummary = ({ children }) => (
  <div className="mt-3 text-sm leading-6">
    <Truncate lines={4}>
      <p dangerouslySetInnerHTML={{ __html: striptags(children) }} />
    </Truncate>
  </div>
);

export const CardAuthorsText = ({ children }) => (
  <div className="mt-3 text-sm leading-6 italic">
    <Truncate lines={2}>
      <p dangerouslySetInnerHTML={{ __html: children }} />
    </Truncate>
  </div>
);

export const InstitutionInfo = ({ institution }) =>
  institution?.title ? (
    <div className="bg-gray-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0 items-center flex">
          <div className="inline-block h-10 w-10 rounded-md overflow-hidden">
            <InstitutionImage image={institution?.image} height={80} />
          </div>
        </div>
        <div className="ml-3">
          <p className="text-sm leading-5 font-medium ">{institution?.title}</p>
          <div className="flex text-sm leading-5 text-gray-700">
            {institution?.location}
          </div>
        </div>
      </div>
    </div>
  ) : null;

export const PersonInfo = ({ person }) =>
  person?.title ? (
    <div className="bg-gray-50 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <PersonImage image={person?.image} height={80} />
        </div>
        <div className="ml-3 w-full">
          <p className="text-sm leading-5 font-medium text-gray-900">
            {person?.title}
          </p>
          <div className="flex text-sm leading-5 text-gray-700">
            {person?.memberInstitution?.title}
          </div>
          <div className="flex text-sm leading-5 text-gray-700">
            {person?.memberInstitution?.location}
          </div>
        </div>
      </div>
    </div>
  ) : null;

export const PersonCard = ({ item }) => (
  <li
    key={item.slug}
    className="flex flex-col items-center space-y-4 w-full h-full"
  >
    <NLink
      href={`/research-partners/${item.slug}`}
      className="max-w-[300px] flex flex-col space-y-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-8 focus:ring-navy-800 transform transition duration-200"
    >
      <div className="relative flex items-center w-full justify-center">
        <PersonImage
          image={item.image}
          height="150"
          width="150"
          imgixParams={{ q: 100 }}
          className="w-40 h-40"
        />
      </div>
      <div className="flex flex-wrap justify-center space-x-1">
        {item.leadershipTypes
          ?.sort((a, b) => a.name.length - b.name.length)
          .map(({ name }) => (
            <RainbowLabel key={name} label={name} />
          ))}
      </div>
      <div className="flex flex-col text-center flex-1 space-y-2">
        <div className="font-jaf text-lg leading-5 !text-gray-900">
          {item.title}
        </div>
        {item?.memberInstitution?.title && (
          <>
            <div className="!text-gray-900">
              {item?.memberInstitution?.title}
            </div>
            <p className={`text-sm text-gray-700`}>
              {item?.memberInstitution?.location}
            </p>
          </>
        )}
      </div>
    </NLink>
  </li>
);

export const PersonCardFragment = gql`
  fragment PersonCardFragment on PersonPage {
    id
    slug
    title
    email
    image {
      src
      title
    }
    leadershipTypes {
      name
    }
    short
    credentials {
      name
    }
    memberInstitution {
      slug
      image {
        src
        title
      }
      title
      location
    }
  }
`;
export const WagtailPersonCardFragment = graphql`
  fragment Wagtail_PersonCardFragment on Wagtail_PersonPage {
    id
    slug
    title
    email
    image {
      src
      title
    }
    leadershipTypes {
      name
    }
    short
    credentials {
      name
    }
    memberInstitution {
      slug
      image {
        src
        title
      }
      title
      location
    }
  }
`;

export const InstitutionCard = ({ item }) => (
  <Card to={`/institutions/${item?.slug}`}>
    <CardImage>
      <InstitutionImage image={item?.image} height={200} />
    </CardImage>
    <CardBody>
      <div className="flex justify-between">
        <div>
          {item?.memberTypes?.map((h) => (
            <SnippetLabel key={h.name} snippet={h} />
          ))}
        </div>
        {item?.logo && (
          <Image
            src={item?.logo?.src}
            alt={item?.logo?.title}
            className="mt-3 h-6"
          />
        )}
      </div>
      <CardTitle>{item.title}</CardTitle>
      {item?.dateJoined && (
        <span className="mt-3 flex items-center text-sm leading-5 ">
          <svg
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
          <span className="mr-1">Joined on</span>
          <time dateTime={item?.dateJoined}>
            {format(
              new Date(item?.dateJoined.replace(/-/g, "/").replace(/T.+/, "")),
              "MMMM dd, yyyy"
            )}
          </time>
        </span>
      )}
      <CardSummary>{item?.short}</CardSummary>
    </CardBody>
  </Card>
);

export const InstitutionCardFragment = gql`
  fragment InstitutionCardFragment on MemberInstitutionPage {
    slug
    title
    short
    image {
      src
      title
    }
    logo {
      src
      title
    }
    memberTypes {
      name
    }
    location
    dateJoined
  }
`;

export const WagtailInstitutionCardFragment = graphql`
  fragment Wagtail_InstitutionCardFragment on Wagtail_MemberInstitutionPage {
    slug
    title
    short
    image {
      src
      title
    }
    logo {
      src
      title
    }
    memberTypes {
      name
    }
    location
    dateJoined
  }
`;

export const PresentationCard = ({ item, showType }) => (
  <Card>
    <CardEntity entity="Presentation" showType={showType} />
    <CardImage>
      <Link to={`/presentations/${item?.slug}`} title={item.title}>
        <PresentationImage image={item?.image} height={200} />
      </Link>
    </CardImage>
    <CardBody>
      <div>
        <Label>{item?.conference?.name}</Label>
        <Link to={`/presentations/${item?.slug}`}>
          <CardTitle>{item.title}</CardTitle>

          {item?.presentationDate && (
            <span className="mt-3 flex items-center text-sm leading-5 ">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                />
              </svg>

              <time dateTime={item?.presentationDate}>
                {format(
                  new Date(
                    item?.presentationDate.replace(/-/g, "/").replace(/T.+/, "")
                  ),
                  "MMMM dd, yyyy"
                )}
              </time>
            </span>
          )}
          <CardSummary>{item?.summary || item?.short}</CardSummary>
          <CardAuthorsText>{item?.authorsText}</CardAuthorsText>
        </Link>
      </div>
    </CardBody>
  </Card>
);

export const PresentationCardFragment = gql`
  fragment PresentationCardFragment on PresentationPage {
    title
    slug
    image {
      src
      title
    }
    presentationDate
    conference {
      name
    }
    short
    summary
    authorsText
  }
`;

export const WagtailPresentationCardFragment = graphql`
  fragment Wagtail_PresentationCardFragment on Wagtail_PresentationPage {
    title
    slug
    image {
      src
      title
    }
    presentationDate
    conference {
      name
    }
    short
    summary
    authorsText
  }
`;

export const PublicationCard = ({ item, showType }) => (
  <Card>
    <CardEntity entity="Publication" showType={showType} />
    <CardImage>
      <Link to={`/publications/${item?.slug}`} title={item.title}>
        <PublicationImage image={item?.image} height={200} />
      </Link>
    </CardImage>
    <CardBody>
      <div>
        <Link to={`/publications/${item?.slug}`}>
          <Label>{item?.journal?.name}</Label>
          <CardTitle>{item.title}</CardTitle>
          {item?.publicationDate && (
            <span className="mt-3 flex items-center text-sm leading-5 ">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                />
              </svg>

              <time dateTime={item?.publicationDate}>
                {format(
                  new Date(
                    item?.publicationDate.replace(/-/g, "/").replace(/T.+/, "")
                  ),
                  "MMMM dd, yyyy"
                )}
              </time>
            </span>
          )}
          <CardSummary>{item?.summary || item?.short}</CardSummary>
          <CardAuthorsText>{item?.authorsText}</CardAuthorsText>
        </Link>
      </div>
    </CardBody>
  </Card>
);

export const PublicationCardFragment = gql`
  fragment PublicationCardFragment on PublicationPage {
    slug
    title
    image {
      src
      title
    }
    publicationDate
    journal {
      name
    }
    short
    summary
    authorsText
  }
`;

export const WagtailPublicationCardFragment = graphql`
  fragment Wagtail_PublicationCardFragment on Wagtail_PublicationPage {
    slug
    title
    image {
      src
      title
    }
    publicationDate
    journal {
      name
    }
    short
    summary
    authorsText
  }
`;

export const ExperimentCard = ({ item, showType }) => (
  <Card to={`/projects/${item?.slug}`}>
    <CardEntity entity="Project" showType={showType} />
    <CardImage>
      <ExperimentImage image={item?.image} height={200} />
    </CardImage>
    <CardBody>
      <div>
        {item.needsFunding && <Label color="green">Support</Label>}
        {differenceInDays(Date.now(), new Date(item.date)) < 30 && (
          <Label color="blue">New</Label>
        )}
        {item?.experimentTypes?.map((p) => (
          <SnippetLabel key={p.name} snippet={p} />
        ))}
        <ExperimentStatusLabel status={item.status?.name} />
        <CardTitle>{item.title}</CardTitle>
        <CardSummary>{item.summary || item.short}</CardSummary>
      </div>
      <CardAuthorsText>
        {[item?.allBrainTumorTypes && "All Brain Tumor Types"]
          .concat(item?.histologies?.map((h) => h.label))
          .filter(Boolean)
          .join(", ")}
      </CardAuthorsText>
    </CardBody>
    {item?.principalInvestigator?.slug && (
      <PersonInfo person={item?.principalInvestigator} />
    )}
    {!item?.principalInvestigator?.slug &&
      item?.meetTheTeam
        ?.filter(({ principalInvestigator }) => principalInvestigator)
        .slice(0, 1)
        .map(({ teamMember }) => (
          <PersonInfo key={teamMember?.slug} person={teamMember} />
        ))}
  </Card>
);

export const ExperimentCardFragment = gql`
  fragment ExperimentCardFragment on ExperimentPage {
    id
    slug
    title
    image {
      src
      title
    }
    needsFunding
    status {
      name
    }
    experimentTypes {
      name
    }
    experimentThemes {
      name
    }
    histologies {
      label
      title
      slug
    }
    short
    summary
    date
    allBrainTumorTypes
    principalInvestigator {
      ...PersonCardFragment
    }
    meetTheTeam {
      teamMember {
        ...PersonCardFragment
      }
      principalInvestigator
    }
  }
  ${PersonCardFragment}
`;

export const WagtailExperimentCardFragment = graphql`
  fragment Wagtail_ExperimentCardFragment on Wagtail_ExperimentPage {
    id
    slug
    title
    image {
      src
      title
    }
    needsFunding
    status {
      name
    }
    experimentTypes {
      name
    }
    experimentThemes {
      name
    }
    histologies {
      label
      title
      slug
    }
    short
    summary
    date
    allBrainTumorTypes
    principalInvestigator {
      ...Wagtail_PersonCardFragment
    }
    meetTheTeam {
      teamMember {
        ...Wagtail_PersonCardFragment
      }
      principalInvestigator
    }
  }
`;

export const HistologyCard = ({ item, showType }) => (
  <Card to={`/histologies/${item.slug}`}>
    <CardEntity entity="Brain Tumor Type" showType={showType} />
    <CardImage>
      <HistologyImage image={item?.image} height={200} />
    </CardImage>
    <CardBody>
      <CardTitle>{item.title}</CardTitle>
      <CardSummary>{item.short}</CardSummary>
      <div>
        <div className="mt-6 flex justify-center">
          <StatGroup>
            {"enrolledSubjects" in item && (
              <StatBlock name="Participants" value={item.enrolledSubjects} />
            )}
            {("projectsData" in item || "projectsBio" in item) && (
              <StatBlock
                name="Projects Supported"
                value={item.projectsData + item.projectsBio}
              />
            )}
          </StatGroup>
        </div>
      </div>
    </CardBody>
  </Card>
);

export const HistologyCardFragment = gql`
  fragment HistologyCardFragment on HistologyPage {
    slug
    title
    label
    image {
      src
      title
    }
    short
    enrolledSubjects
    projectsBio
    projectsData
  }
`;

export const WagtailHistologyCardFragment = graphql`
  fragment Wagtail_HistologyCardFragment on Wagtail_HistologyPage {
    slug
    title
    label
    image {
      src
      title
    }
    short
    enrolledSubjects
    projectsBio
    projectsData
  }
`;

export const DonorLevel = ({ level }) => {
  if (level === "Diamond") {
    return (
      <svg
        id="Layer_1"
        className="h-6 w-6 text-blue-900"
        viewBox="0 0 64 64"
        fill="currentColor"
      >
        <path
          id="Diamond"
          d="M63.6870499,18.5730648L48.7831497,4.278266c-0.1855011-0.1758003-0.4316025-0.4813001-0.6870003-0.4813001
	H15.9037514c-0.2553005,0-0.5014,0.3054998-0.6870003,0.4813001l-14.9038,14.1908998
	c-0.374,0.3535004-0.4184,0.9855995-0.1025,1.3917999c0.21,0.2703991,30.8237991,39.7256012,31.0517006,39.9812012
	c0.1022987,0.1149979,0.2402992,0.2215996,0.3428001,0.266098c0.2763996,0.1206017,0.5077,0.1296997,0.7900982,0.0065002
	c0.1025009-0.0444984,0.2404022-0.1348991,0.3428001-0.2499008c0.0151024-0.0168991,0.0377007-0.0224991,0.0517006-0.0404968
	L63.789547,19.9121666C64.1054459,19.5058651,64.0610504,18.9265652,63.6870499,18.5730648z M15.6273508,6.4344659
	l4.9945002,11.3625011H3.6061509L15.6273508,6.4344659z M24.0795517,17.7969666l7.9203987-11.2617006l7.9204979,11.2617006
	H24.0795517z M40.7191467,19.7969666l-8.7191963,34.8769989l-8.719099-34.8769989H40.7191467z M33.9257507,5.7969656h12.5423012
	l-4.8240013,10.9746008L33.9257507,5.7969656z M22.3559513,16.7715664L17.53195,5.7969656h12.5423012L22.3559513,16.7715664z
	 M21.2191505,19.7969666l8.6596012,34.638401L2.975451,19.7969666H21.2191505z M42.7808495,19.7969666h18.2436981
	l-26.9032974,34.638401L42.7808495,19.7969666z M43.3781471,17.7969666l4.9944992-11.3625011l12.0212021,11.3625011H43.3781471z"
        />
      </svg>
    );
  } else if (level === "Gold") {
    return (
      <svg
        className="h-6 w-6 text-yellow-900"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M11.18,14.356c0-1.451,1.1-2.254,2.894-3.442C16.268,9.458,19,7.649,19,3.354
       c0-0.387-0.317-0.699-0.709-0.699h-3.43C14.377,1.759,12.932,0.8,10,0.8c-2.934,0-4.377,0.959-4.862,1.855H1.707
       C1.316,2.655,1,2.968,1,3.354c0,4.295,2.73,6.104,4.926,7.559c1.794,1.188,2.894,1.991,2.894,3.442v1.311
       c-1.884,0.209-3.269,0.906-3.269,1.736c0,0.994,1.992,1.799,4.449,1.799s4.449-0.805,4.449-1.799c0-0.83-1.385-1.527-3.269-1.736
       C11.18,15.666,11.18,14.356,11.18,14.356z M13.957,9.3c0.566-1.199,1.016-2.826,1.088-5.246h2.51
       C17.315,6.755,15.693,8.118,13.957,9.3z M10,2.026c2.732-0.002,3.799,1.115,3.798,1.529c0,0.418-1.066,1.533-3.798,1.535
       C7.268,5.089,6.201,3.974,6.201,3.556C6.2,3.142,7.268,2.024,10,2.026z M2.445,4.054h2.509C5.027,6.474,5.475,8.101,6.043,9.3
       C4.307,8.118,2.684,6.755,2.445,4.054z"
        />
      </svg>
    );
  } else if (level === "Silver") {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="currentColor"
        className="h-6 w-6 text-gray-500"
      >
        <path
          d="M10,10c0.528,0,1.026,0.104,1.504,0.256L5.427,1.141C5.368,1.053,5.27,1,5.164,1H1.608
	C1.491,1,1.42,1.131,1.486,1.229l6.231,9.347C8.404,10.22,9.173,10,10,10z M18.392,1h-3.556c-0.106,0-0.204,0.053-0.263,0.141
	L10.75,6.875l2,3l5.764-8.646C18.579,1.131,18.509,1,18.392,1z M10,11c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4
	S12.209,11,10,11z M12.112,15.117c0.062,0.064,0.052,0.158-0.022,0.208s-0.095,0.153-0.049,0.229
	c0.047,0.076,0.018,0.165-0.065,0.199s-0.125,0.13-0.095,0.214c0.03,0.084-0.017,0.165-0.104,0.181s-0.149,0.101-0.137,0.189
	s-0.051,0.158-0.14,0.155c-0.089-0.003-0.167,0.068-0.174,0.156s-0.083,0.144-0.169,0.123s-0.178,0.031-0.203,0.117
	s-0.111,0.124-0.191,0.085c-0.08-0.039-0.18-0.006-0.222,0.072s-0.134,0.098-0.205,0.043s-0.175-0.044-0.232,0.024
	s-0.151,0.068-0.209,0s-0.162-0.079-0.232-0.024s-0.162,0.035-0.205-0.043s-0.142-0.111-0.222-0.072s-0.166,0-0.191-0.085
	s-0.116-0.138-0.203-0.117s-0.163-0.034-0.169-0.123S8.589,16.489,8.5,16.491c-0.089,0.003-0.152-0.067-0.14-0.155
	s-0.05-0.173-0.137-0.189s-0.135-0.097-0.104-0.181s-0.013-0.18-0.095-0.214s-0.111-0.123-0.065-0.199
	c0.047-0.076,0.025-0.179-0.049-0.229s-0.083-0.144-0.022-0.208c0.062-0.064,0.062-0.169,0-0.234s-0.052-0.158,0.022-0.208
	s0.095-0.153,0.049-0.229c-0.047-0.076-0.018-0.165,0.065-0.199s0.125-0.13,0.095-0.214s0.017-0.165,0.104-0.181
	s0.149-0.101,0.137-0.189s0.051-0.158,0.14-0.155c0.089,0.003,0.167-0.068,0.174-0.156s0.083-0.144,0.169-0.123
	s0.178-0.031,0.203-0.117s0.111-0.124,0.191-0.085s0.18,0.006,0.222-0.072s0.134-0.098,0.205-0.043s0.175,0.044,0.232-0.024
	s0.151-0.068,0.209,0s0.162,0.079,0.232,0.024s0.162-0.035,0.205,0.043s0.142,0.111,0.222,0.072c0.08-0.039,0.166,0,0.191,0.085
	s0.116,0.138,0.203,0.117s0.163,0.034,0.169,0.123s0.085,0.159,0.174,0.156s0.152,0.067,0.14,0.155s0.05,0.173,0.137,0.189
	s0.135,0.097,0.104,0.181s0.013,0.18,0.095,0.214s0.111,0.123,0.065,0.199c-0.047,0.076-0.025,0.179,0.049,0.229
	s0.083,0.144,0.022,0.208S12.05,15.053,12.112,15.117z"
        />
      </svg>
    );
  } else if (level === "Bronze") {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="currentColor"
        className="h-6 w-6 text-orange-900"
      >
        <path
          d="M10,10c0.528,0,1.026,0.104,1.504,0.256L5.427,1.141C5.368,1.053,5.27,1,5.164,1H1.608
	C1.491,1,1.42,1.131,1.486,1.229l6.231,9.347C8.404,10.22,9.173,10,10,10z M18.392,1h-3.556c-0.106,0-0.204,0.053-0.263,0.141
	L10.75,6.875l2,3l5.764-8.646C18.579,1.131,18.509,1,18.392,1z M10,11c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4
	S12.209,11,10,11z M12.112,15.117c0.062,0.064,0.052,0.158-0.022,0.208s-0.095,0.153-0.049,0.229
	c0.047,0.076,0.018,0.165-0.065,0.199s-0.125,0.13-0.095,0.214c0.03,0.084-0.017,0.165-0.104,0.181s-0.149,0.101-0.137,0.189
	s-0.051,0.158-0.14,0.155c-0.089-0.003-0.167,0.068-0.174,0.156s-0.083,0.144-0.169,0.123s-0.178,0.031-0.203,0.117
	s-0.111,0.124-0.191,0.085c-0.08-0.039-0.18-0.006-0.222,0.072s-0.134,0.098-0.205,0.043s-0.175-0.044-0.232,0.024
	s-0.151,0.068-0.209,0s-0.162-0.079-0.232-0.024s-0.162,0.035-0.205-0.043s-0.142-0.111-0.222-0.072s-0.166,0-0.191-0.085
	s-0.116-0.138-0.203-0.117s-0.163-0.034-0.169-0.123S8.589,16.489,8.5,16.491c-0.089,0.003-0.152-0.067-0.14-0.155
	s-0.05-0.173-0.137-0.189s-0.135-0.097-0.104-0.181s-0.013-0.18-0.095-0.214s-0.111-0.123-0.065-0.199
	c0.047-0.076,0.025-0.179-0.049-0.229s-0.083-0.144-0.022-0.208c0.062-0.064,0.062-0.169,0-0.234s-0.052-0.158,0.022-0.208
	s0.095-0.153,0.049-0.229c-0.047-0.076-0.018-0.165,0.065-0.199s0.125-0.13,0.095-0.214s0.017-0.165,0.104-0.181
	s0.149-0.101,0.137-0.189s0.051-0.158,0.14-0.155c0.089,0.003,0.167-0.068,0.174-0.156s0.083-0.144,0.169-0.123
	s0.178-0.031,0.203-0.117s0.111-0.124,0.191-0.085s0.18,0.006,0.222-0.072s0.134-0.098,0.205-0.043s0.175,0.044,0.232-0.024
	s0.151-0.068,0.209,0s0.162,0.079,0.232,0.024s0.162-0.035,0.205,0.043s0.142,0.111,0.222,0.072c0.08-0.039,0.166,0,0.191,0.085
	s0.116,0.138,0.203,0.117s0.163,0.034,0.169,0.123s0.085,0.159,0.174,0.156s0.152,0.067,0.14,0.155s0.05,0.173,0.137,0.189
	s0.135,0.097,0.104,0.181s0.013,0.18,0.095,0.214s0.111,0.123,0.065,0.199c-0.047,0.076-0.025,0.179,0.049,0.229
	s0.083,0.144,0.022,0.208S12.05,15.053,12.112,15.117z"
        />
      </svg>
    );
  }
  return null;
};

export const FoundationCard = ({ item, showType }) => (
  <Card to={`/partnerships/${item?.slug}`}>
    <CardEntity entity="Foundation" showType={showType} />
    <div className="border-b border-gray-100 relative">
      <CardImage>
        <div className="flex flex-shrink-0 justify-center items-center content-center w-full h-full px-10">
          <FoundationImage image={item?.logo} height={150} />
        </div>
      </CardImage>
      {!!item?.donorLevel?.name && (
        <span className="absolute bottom-0 right-0 h-12 w-12 rounded-full text-white shadow-lg bg-white -mb-6 mr-12 flex justify-center items-center">
          <DonorLevel level={item?.donorLevel?.name} />
        </span>
      )}
    </div>
    <div className="bg-gray-100">
      <CardBody>
        <div>
          {item?.foundationTypes?.map((h) => (
            <SnippetLabel key={h.name} snippet={h} />
          ))}
          {!!item?.yearsSupporting && (
            <SnippetLabel
              snippet={{
                name: `${item?.yearsSupporting} year${
                  item?.yearsSupporting > 1 ? "s" : ""
                }`,
              }}
            />
          )}
        </div>
        <CardTitle>{item.title}</CardTitle>
        <CardSummary>{item.short}</CardSummary>
        <CardAuthorsText>{item?.location}</CardAuthorsText>
      </CardBody>
    </div>
  </Card>
);

export const FoundationCardFragment = gql`
  fragment FoundationCardFragment on FoundationPartnershipPage {
    slug
    lastPublishedAt
    title
    link
    short
    location
    logo {
      title
      src
    }
    foundationTypes {
      name
    }
    donorLevel {
      name
    }
    partnershipType {
      name
    }
    yearsSupporting
  }
`;

export const WagtailFoundationCardFragment = graphql`
  fragment Wagtail_FoundationCardFragment on Wagtail_FoundationPartnershipPage {
    slug
    lastPublishedAt
    title
    link
    short
    location
    logo {
      title
      src
    }
    foundationTypes {
      name
    }
    donorLevel {
      name
    }
    partnershipType {
      name
    }
    yearsSupporting
  }
`;

export const ArticleCard = ({ item, showType = false }) => (
  <Card>
    <CardEntity entity="Article" showType={showType} />
    <CardImage>
      <Link to={`/news/${item?.slug}`} title={item.title}>
        <PublicationImage image={item?.image} height={200} />
      </Link>
    </CardImage>
    <CardBody>
      <div>
        <Link to={`/news/${item?.slug}`}>
          <CardTitle>{item.title}</CardTitle>
          {item?.firstPublishedAt && (
            <span className="mt-3 flex items-center text-sm leading-5 ">
              <svg
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clipRule="evenodd"
                />
              </svg>

              <time dateTime={item?.goLiveAt || item?.firstPublishedAt}>
                {format(
                  new Date(
                    (item?.goLiveAt || item?.firstPublishedAt)
                      ?.replace(/-/g, "/")
                      .replace(/T.+/, "")
                  ),
                  "MMMM dd, yyyy"
                )}
              </time>
            </span>
          )}
          <CardSummary>{item?.short}</CardSummary>
          <CardAuthorsText>
            {item?.articleAuthors
              ?.map(({ articleAuthor }) => articleAuthor.name)
              .join(", ")}
          </CardAuthorsText>
        </Link>
      </div>
    </CardBody>
  </Card>
);

export const ArticleCardFragment = gql`
  fragment ArticleCardFragment on ArticlePage {
    firstPublishedAt
    goLiveAt
    slug
    title
    image {
      src
      title
    }
    short
    articleAuthors {
      articleAuthor {
        name
      }
    }
  }
`;

export const WagtailArticleCardFragment = graphql`
  fragment Wagtail_ArticleCardFragment on Wagtail_ArticlePage {
    firstPublishedAt
    goLiveAt
    slug
    title
    image {
      src
      title
    }
    short
    articleAuthors {
      articleAuthor {
        name
      }
    }
  }
`;

export const InformaticsToolDataCard = ({ item, data }) => (
  <BorderlessCard>
    <CardImage>
      <a
        href={item?.link}
        title={item?.informaticsTool?.name}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="flex flex-shrink-0 justify-center items-center content-center w-full h-full px-10">
          <FoundationImage image={item?.informaticsTool?.image} height={150} />
        </div>
      </a>
    </CardImage>

    <CardBody>
      <a
        href={item?.link}
        target="_blank"
        rel="noreferrer"
        title={item?.informaticsTool?.name}
      >
        <CardTitle>{item?.informaticsTool?.name}</CardTitle>
        <CardSummary>{item?.informaticsTool?.short}</CardSummary>
        <CardSummary>The following data are available:</CardSummary>
        {data.map((i) => (
          <Label key={i.name}>{i.name}</Label>
        ))}
      </a>
    </CardBody>
  </BorderlessCard>
);

export const InformaticsToolCard = ({ item }) => (
  <BorderlessCard>
    <CardImage>
      <a
        href={item?.link}
        title={item?.informaticsTool?.name}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="flex flex-shrink-0 justify-center items-center content-center w-full h-full px-10">
          <FoundationImage image={item?.informaticsTool?.image} height={150} />
        </div>
      </a>
    </CardImage>

    <CardBody>
      <a href={item?.link} title={item?.informaticsTool?.name}>
        <CardTitle>{item?.informaticsTool?.name}</CardTitle>
        <CardSummary>{item?.informaticsTool?.description}</CardSummary>
      </a>
    </CardBody>
  </BorderlessCard>
);

export const InformaticsToolCardFragment = gql`
  fragment InformaticsToolCardFragment on RelatedInformaticsTool {
    informaticsTool {
      name
      description
      short
      link
      image {
        title
        src
      }
    }
    link
  }
`;

export const WagtailInformaticsToolCardFragment = graphql`
  fragment Wagtail_InformaticsToolCardFragment on Wagtail_RelatedInformaticsTool {
    informaticsTool {
      name
      description
      short
      link
      image {
        title
        src
      }
    }
    link
  }
`;

export const ResearchPartnershipCard = ({ item, showType }) => (
  <Card>
    <CardEntity entity="ResearchPartnership" showType={showType} />
    <CardImage>
      <Link to={`/research-partnerships/${item?.slug}`} title={item.title}>
        <div className="flex flex-shrink-0 justify-center items-center content-center w-full h-full px-10">
          <FoundationImage image={item?.logo} height={150} />
        </div>
      </Link>
    </CardImage>
    <div className="bg-gray-100">
      <CardBody>
        <Link to={`/research-partnerships/${item.slug}`}>
          <CardTitle>{item.title}</CardTitle>
          <CardSummary>{item.short}</CardSummary>
        </Link>
      </CardBody>
    </div>
  </Card>
);

export const ResearchPartnershipCardFragment = gql`
  fragment ResearchPartnershipCardFragment on ResearchPartnershipPage {
    slug
    lastPublishedAt
    title
    link
    short
    logo {
      title
      src
    }
  }
`;

export const WagtailResearchPartnershipCardFragment = graphql`
  fragment Wagtail_ResearchPartnershipCardFragment on Wagtail_ResearchPartnershipPage {
    slug
    lastPublishedAt
    title
    link
    short
    logo {
      title
      src
    }
  }
`;

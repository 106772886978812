import * as React from "react";

import { Smallcaps } from "./Prose";
import { Image } from "./Image";

export const StatTitle = ({ children }) => (
  <p className="mt-8">
    <Smallcaps>{children}</Smallcaps>
  </p>
);

export const StatGroup = ({ children }) => (
  <div className="flex space-x-12">{children}</div>
);

export const StatBlock = ({
  icon,
  name,
  value,
  long,
}: {
  icon: string;
  name: string;
  value: number;
  long?: string;
}) => (
  <div className="flex flex-col">
    <div className="flex items-center space-x-2 justify-center">
      {icon && <Image className="w-8 h-8" src={icon} alt="name" />}
      <div className="text-2xl leading-8 font-extrabold  sm:text-3xl sm:leading-9">
        {value}
      </div>
    </div>
    <abbr title={long} className=" text-base leading-6 font-medium ">
      {name}
    </abbr>
  </div>
);

export const IconStats = ({
  count,
  title,
  image,
  onClick = null,
  onClickCheck = false,
}) => {
  const content = (
    <div className="flex items-center space-x-3 p-2 py-3">
      <div className="h-12 w-12">{image}</div>
      <div>
        <span className="text-2xl leading-8 font-extrabold sm:text-3xl sm:leading-9">
          {count}
        </span>
        <Smallcaps>{title}</Smallcaps>
      </div>
    </div>
  );

  if (count === 0 || !onClick || !onClickCheck) {
    return content;
  }

  return (
    <div
      className="cursor-pointer hover:bg-gray-100 rounded-md"
      onClick={onClick}
    >
      {content}
    </div>
  );
};
